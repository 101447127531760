
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import editableInput from 'vue-color/src/components/common/EditableInput.vue';

@Component({
   components: {
      'ed-in': editableInput,
   },
})
export default class ColorCompactPallet extends Vue {
   @Prop({ type: [Array, Object, String], default: '' }) value!: any[] | any | string;

   @Prop({ type: Array }) palette!: string[];

   @Prop({ type: String, default: '' }) reset_color!: string;

   colors: any[] = [];

   mounted(): void {
      this.onChangeValue();
   }

   handlerClick(c: string) {
      if (c == this.reset_color) {
         this.$emit('input', [{ hex: c.toUpperCase() }]);
         return;
      }

      let colors = Array.isArray(this.value) ? this.value : [this.value];
      colors = colors.map(c => ({
         hex: (typeof c == 'string' ? c : c.hex).toUpperCase(),
      }));
      colors = colors.filter(({ hex }) => hex.toUpperCase() != this.reset_color.toUpperCase());

      if (colors.filter(({ hex }) => hex == c).length) {
         colors = colors.filter(({ hex }) => hex != c);
      } else {
         colors = [...colors, { hex: c }];
      }

      this.$emit('input', colors);
   }

   @Watch('value')
   onChangeValue() {
      this.colors = (Array.isArray(this.value) ? this.value : [this.value]).map(c => ({
         hex: (typeof c == 'string' ? c : c.hex)?.toUpperCase(),
      }));
   }
}
